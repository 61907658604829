import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const IndexPage = () => (
  <Layout>
    <SEO title="About" />
    <div className="about_holder">

    <p className="about_copy">One day in 2019, Paul Tomlinson was flicking through his vinyl collection, when he randomly picked out Led Zepplin's Physical Graffiti LP. The iconic album sleeve features a New York tenement building, on which you can change the images and people that appear in the windows. While playing around with the LP sleeve, Paul thought this concept could also form the basis of a super stylish (and fun!) board game, so he pitched the idea to his old mate and renowned illustrator  <a rel="noreferrer" target="_blank" href="http://www.stanleychow.co.uk/">Stan Chow</a>. Funnily enough, it turned out that this LP sleeve is also one of Stan's favourites, so much so that he has given lectures on the album's design and visits the old tenement block on St Mark's Place whenever he is in New York. This idea was clearly meant to be, and hence the Grand Guess Hotel was born. Paul subsequently created Mugs Games as the company to launch this board game, and hopefully many more in the future!</p>
    <StaticImage
      src="../images/ledzep.png"
      width={400}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="MUGS GAMES"
      className="about_image"
    />

    </div>
  </Layout>
)

export default IndexPage
